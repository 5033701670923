import React from 'react';
import {
    Button, Checkbox,
    Message, MessageHeader,
    Form,
    Modal, ModalContent, Icon
} from 'semantic-ui-react';
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import {
    getAddress,
    getPatientTitle,
    getToken,
    PatientTitleCase,
} from "../../../utils/common";
import styles from "../index.module.css";

interface State {
    openModal: boolean,
    loading: boolean,
    isExporting: boolean,
    addTimeRange: boolean,
    startDate: Date,
    endDate: Date,
}
interface Props {
    onClose: () => void;
}

class ExportMassiveEventModal extends React.Component<Props> {
    state: State = {
        openModal: true,
        loading: false,
        isExporting: false,
        addTimeRange: false,
        startDate: new Date(),
        endDate: new Date(),
    };

    clearState = (): void => {
        this.setState({
            openModal: false,
            //startDate: null,
            //endDate: null,
            loading: false
        }, () => {
            this.props.onClose(); // Call the onClose callback passed from the parent
        });
    };

    viewFile = async (url: string, prefix: string) => {
        this.setState({isExporting: true});
        fetch(url, {
            headers: {
                'x-access-token': getToken()
            }
        })
            .then((response) => {
                //fileName = response.headers.get('Content-Disposition')!.split('filename=')[1].split(';')[0];
                return response.blob();
            })
            .then((blob) => {
                const _url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = _url;
                link.setAttribute('download', prefix + (new Date()).toISOString() + ".xlsx");
                document.body.appendChild(link);
                link.click();
                this.setState({isExporting: false});
                //window.open(_url, "_self");
            }).catch((err) => {
            console.log(err);
            this.setState({isExporting: false});
        });
    };

    exportMassiveEvent = async (): Promise<void> => {
        this.setState({loading: true});
        let url = this.state.addTimeRange
            ? `${getAddress()}/patient/exportMassiveEvent?startDate=${this.state.startDate.toISOString().slice(0, 10)}&endDate=${this.state.endDate.toISOString().slice(0, 10)}`
            : `${getAddress()}/patient/exportMassiveEvent`;
        this.viewFile(url, "export_massive_event_");
        this.clearState()
    };

    render() {
        const headerLabel: string = `Экспорт массовых рассылок`;
        const exportLabel: string = `Экспортировать`;
        const cancelLabel: string = `Отмена`;

        return (
            <div className={styles.container}>

                <Modal
                    onClose={this.clearState}
                    open={this.state.openModal}
                    closeIcon
                >
                    <Modal.Header>{headerLabel}</Modal.Header>
                    <ModalContent>
                        <Form>
                            <div style={{marginBottom: "10px"}}>
                                <Checkbox
                                    label={`Добавить временной диапазон выгрузки`}
                                    checked={this.state.addTimeRange}
                                    onClick={() => this.setState({ addTimeRange: !this.state.addTimeRange })}
                                />
                            </div>
                            {this.state.addTimeRange &&
                                <>
                                    <div>
                                        <label>Дата начала выгрузки</label>
                                        <Icon
                                            name='info circle'
                                            size='small'
                                            color='grey'
                                            className={styles.icon_under_text}
                                            title='Даты начала и окончания выгрузки задают диапазон для Даты начала массовой рассылки'
                                        />
                                        <br/>
                                        <DatePicker
                                            selected={this.state.startDate}
                                            onChange={(date: Date) => {
                                                this.setState({
                                                    startDate: date,
                                                    endDate: date
                                                })
                                            }}
                                            locale={ru}
                                            dateFormat="dd.MM.yyyy"
                                        />
                                    </div>

                                    <div style={{marginTop: '15px'}}>
                                        <label style={{marginTop: '10px'}}>Дата окончания выгрузки</label><br/>
                                        <DatePicker
                                            selected={this.state.endDate}
                                            onChange={(date: Date) => {
                                                this.setState({
                                                    endDate: date
                                                })
                                            }}
                                            locale={ru}
                                            dateFormat="dd.MM.yyyy"
                                            minDate={this.state.startDate}
                                            maxDate={new Date(this.state.startDate.getTime() + 30 * 24 * 60 * 60 * 1000)}
                                        />
                                    </div>
                                    {/*<Message>*/}
                                    {/*    <p>Даты начала и окончания выгрузки задают диапазон для <i>Даты начала массовой рассылки</i></p>*/}
                                    {/*</Message>*/}
                                </>
                            }
                        </Form>
                    </ModalContent>
                    <Modal.Actions>
                        <Button
                            content={exportLabel}
                            onClick={this.exportMassiveEvent}
                            positive
                        />
                        <Button
                            color={'grey'}
                            onClick={() => this.clearState()}
                        >
                            {cancelLabel}
                        </Button>
                    </Modal.Actions>
                </Modal>

            </div>

        );
    }
}

export default ExportMassiveEventModal;