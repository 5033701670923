import React, {ChangeEvent, FormEvent, SyntheticEvent} from 'react';
import {
    Button,
    ButtonGroup,
    Segment,
    Radio,
    TextAreaProps,
    Checkbox,
    CheckboxProps,
    Dropdown,
    DropdownItemProps,
    DropdownProps,
    Form,
    Icon,
    InputOnChangeData,
    Message,
    MessageContent,
    MessageHeader,
    Modal
} from 'semantic-ui-react';
import DatePicker from "react-datepicker";
import ru from "date-fns/locale/ru";
import ReactInputMask from "react-input-mask";
import {Appointment, Patient, SpecialGroup, SpecialGroupOptions} from "../../dashboard";
import {
    AppMode,
    DoctorTitleCase,
    GeneralTitleCase,
    getDoctorTitle,
    getGeneralTitle, getPatientTitle,
    getSpecialGroupId,
    getTZ, PatientTitleCase,
    safeParseInt
} from "../../../utils/common";
import {
    getPatientDailyEventEndDate,
    getPatientMonthlyEventEndDate,
    getPatientWeeklyEventEndDate
} from "../../../helpers/CalendarEventsRepeater";
import agent, {PaginateOptions} from "../../../agent";
import moment from "moment/moment";
import styles from "../index.module.css";
import {Utils} from "../../../helpers/Utils";
import {SpecialGroupPaginationResponse} from "../../workTableSpecialGroups/SpecialGroupList";
import {AppointmentPaginationResponse} from "../../workTableAppointments/AppointmentList";

interface IMassiveEventModal {
    type: 'selected' | 'all' | 'specialgroups'
    selectedPatientIds?: string[]
    // setOpenCreateMassiveEventSelected?: (visible: boolean) => void
    // setOpenCreateMassiveEventAll?: (visible: boolean) => void
    // setOpenCreateMassiveEventSpecialGroups?: (visible: boolean) => void
}
type TypeDescriptionFormatting = 'bold' | 'italic' | 'underline';

interface Props {
    patientIds?: string[];
    type?: 'selected' | 'all' | 'specialgroups';
    id?: string;
    editModal?: boolean
}

interface State {
    eventId: string,
    openModal: boolean,
    events: CalendarEvent[],
    description: string,
    descriptionFormatting: boolean,
    descriptionTypeFormatting: TypeDescriptionFormatting,
    appointment?: boolean | null,
    appointmentId?: string,
    appointmentList: Appointment[],
    specialgroupids: string[],
    specialgroups: SpecialGroup[],
    searchSpecialGroup: string,
    eventType: string,
    repeatType: string,
    finishType: string,
    repeatByDaysCount: number,
    repeatByWeeksCount: number,
    repeatByMonthsCount: number,
    repeatSeveralTimesCount: number,
    repeatDays: string[],
    checkboxRepeatDays: Map<string, boolean>,
    startDate?: Date,
    endDate?: Date,
    cancellationTimeframeFrom: Date | null,
    cancellationTimeframeTo: Date | null,
    time: string,
    loading: boolean,
    editMode: boolean,
    journal: EventJournalRecord[],
    checkboxSelectedItemsSwitch: boolean,
    currentCalendarDate: Date,
    eventHasBeenChanged: boolean,
    cancellationTimeframeHasBeenChanged: boolean,
    prevState: State | null,
    calendarSelectedDate: Date | null,
    filterEventType: string | undefined;
}
export interface CalendarEvent {
    groupId: string,
    title: string,
    start: string,
    backgroundColor?: string,
    url?: string
}
export interface PatientMassiveEvent {
    _id: string,
    patientId: string,
    startDate: Date,
    endDate: Date,
    eventType: string,
    finishType: string,
    repeatType: string,
    repeatSeveralTimesCount: number,
    repeatByDaysCount: number,
    repeatByWeeksCount: number,
    repeatByMonthsCount: number,
    repeatDays: string[],
    description: string,
    appointment?: boolean | null,
    appointmentId?: string,
    specialgroupids: string[],
    specialgroups: SpecialGroup[],
    searchSpecialGroup: string,
    journal: EventJournalRecord[],
    cancellationTimeframeFrom: Date | null,
    cancellationTimeframeTo: Date | null,
}

export interface PatientMassiveEventFull extends PatientMassiveEvent {
    patient: Patient,
}

export enum FinishTypes {
    COUNTS = 'counts',
    DATE = 'date',
}

export class EventTypes {
    static TAKE_CURE = 'Принять лекарство';
    static VISIT_DOCTOR = 'Посетить врача';
    static GET_TESTED = 'Пройти обследование';
    static INFO = 'Информация для Вас';
    static HOSPITALIZATION = 'Госпитализация';
    static REMINDER = 'Напоминание для врача';
    static DATE_SOCIAL_WORKER = `Дата встречи с ${getDoctorTitle(DoctorTitleCase.om)}`;
    static SUPPORT_MEASURES = 'Меры поддержки';
    static REMINDER_VO = `Напоминание для ${getDoctorTitle(DoctorTitleCase.a)}`;
}

export enum RepeatTypes {
    NO_REPEATABLE = 'Не повторять',
    DAILY = 'Ежедневно',
    WEEKLY = 'Еженедельно',
    MONTHLY = 'Ежемесячно'
}

interface DropdownOptions {
    key: string,
    text: string,
    value: string,
}

export enum DaysOfWeek {
    MONDAY = 'Monday',
    TUESDAY = 'Tuesday',
    WEDNESDAY = 'Wednesday',
    THURSDAY = 'Thursday',
    FRIDAY = 'Friday',
    SATURDAY = 'Saturday',
    SUNDAY = 'Sunday',
}

const daysCheckboxes = [
    {
        name: DaysOfWeek.MONDAY,
        key: 'monday',
        label: 'ПН',
    },
    {
        name: DaysOfWeek.TUESDAY,
        key: 'tuesday',
        label: 'ВТ',
    },
    {
        name: DaysOfWeek.WEDNESDAY,
        key: 'wednesday',
        label: 'СР',
    },
    {
        name: DaysOfWeek.THURSDAY,
        key: 'thursday',
        label: 'ЧТ',
    },
    {
        name: DaysOfWeek.FRIDAY,
        key: 'friday',
        label: 'ПТ',
    },
    {
        name: DaysOfWeek.SATURDAY,
        key: 'saturday',
        label: 'СБ',
    },
    {
        name: DaysOfWeek.SUNDAY,
        key: 'sunday',
        label: 'ВС',
    },
];

export interface EventJournalRecord {
    id: string,
    status: string,
    date: Date,
    description: string,
    statusDate?: Date
}

class MassiveEventModal extends React.PureComponent<Props, State> {
    state: State = {
        eventId: '',
        // openModal: false,
        openModal: true,
        events: [],
        repeatType: '',
        eventType: '',
        description: '',
        descriptionFormatting: false,
        descriptionTypeFormatting: 'bold',
        appointment: false,
        appointmentId: '',
        appointmentList: [],
        specialgroupids: [],
        specialgroups: [],
        searchSpecialGroup: '',
        finishType: FinishTypes.COUNTS,
        repeatSeveralTimesCount: 0,
        repeatByWeeksCount: 0,
        repeatByDaysCount: 0,
        repeatDays: [],
        checkboxRepeatDays: new Map(),
        cancellationTimeframeFrom: null,
        cancellationTimeframeTo: null,
        time: '',
        loading: false,
        editMode: true,
        // editMode: false,
        journal: [],
        checkboxSelectedItemsSwitch: false,
        currentCalendarDate: new Date(),
        eventHasBeenChanged: false,
        cancellationTimeframeHasBeenChanged: false,
        prevState: null,
        calendarSelectedDate: null,
        repeatByMonthsCount: 1,
        filterEventType: undefined
    };

    clearState = (): void => {
        this.setState({
            eventId: '',
            openModal: false,
            repeatType: '',
            eventType: '',
            description: '',
            descriptionFormatting: false,
            descriptionTypeFormatting: 'bold',
            appointment: false,
            appointmentId: '',
            specialgroupids: [],
            specialgroups: [],
            searchSpecialGroup: '',
            finishType: FinishTypes.COUNTS,
            repeatSeveralTimesCount: 0,
            repeatByWeeksCount: 0,
            repeatByDaysCount: 0,
            repeatDays: [],
            checkboxRepeatDays: new Map(),
            time: '',
            editMode: true,
            journal: [],
            checkboxSelectedItemsSwitch: false,
            cancellationTimeframeFrom: null,
            cancellationTimeframeTo: null,
            eventHasBeenChanged: false,
            cancellationTimeframeHasBeenChanged: false,
            prevState: null,
            calendarSelectedDate: null
        });
        window.location.reload();
    };
    async componentDidMount() {
        // await this.load();
        this.setState({loading: true});
        if (!this.props.id) {
            this.setState({editMode: false});
        }
        // else {
        //     const data = await agent.PatientMassiveEventProvider.open(this.props.id)
        //     const { cancellationTimeframeFrom, cancellationTimeframeTo, description, endDate, eventIds, eventType, finishType, journal, patientIds, repeatByDaysCount, repeatByMonthsCount, repeatByWeeksCount, repeatDays, repeatSeveralTimesCount, repeatType, specialgroupids, startDate, type, _id } = data;
        //     console.log('test')
        //     this.setState({
        //         // cancellationTimeframeFrom, cancellationTimeframeTo,
        //         description, endDate,
        //         // eventIds,
        //         // eventType, finishType, journal,
        //         // patientIds,
        //         // repeatByDaysCount, repeatByMonthsCount, repeatByWeeksCount, repeatDays, repeatSeveralTimesCount, repeatType,
        //         specialgroupids, startDate,
        //         // type,
        //         // _id
        //     });
        //     console.log('data', data)
        //     // this.setState({ description: data });
        // }
        // await this.getEvents();
        const responseSp: SpecialGroupPaginationResponse = await agent.SpecialGroupProvider.getList();
        this.setState({specialgroups: responseSp.docs, loading: false});
        const specialGroupIds = await getSpecialGroupId();
        if (specialGroupIds)
            this.setState({
                specialgroupids: specialGroupIds,
            });
        await this.getAppointmentList();
        console.log('log', this.state, this.props)
    };

    async load() {
        this.setState({loading: true});
        // await this.getEvents();
    };

    getAppointmentList = async (): Promise<void> => {
        this.setState({loading: true});

        const paginateOptions: PaginateOptions = {
            limit: 10000,
            page: 1,
            search: "",
            sort: "name"
        };

        const response: AppointmentPaginationResponse = await agent.AppointmentProvider.getList(paginateOptions);

        if (response) {
            this.setState({
                appointmentList: response.docs
            });
        }
        this.setState({loading: false});
    };

    onChangeRepeatingDays = (e: any, data: CheckboxProps) => {
        const item = data.value as string;
        const isChecked = data.checked as boolean;

        this.setState((prevState) => {
            return {
                checkboxRepeatDays: prevState.checkboxRepeatDays.set(item, isChecked),
                checkboxSelectedItemsSwitch: !prevState.checkboxSelectedItemsSwitch
            }
        });
    };

    eventDateLabel: string = 'Дата напоминания';
    eventStartDateLabel: string = 'Дата начала';

    getSpecialGroupsOptions  = (): SpecialGroupOptions[] => {
        return this.state.specialgroups.map((el: SpecialGroup) => ({
            key: el._id,
            text: `${el.name}`,
            value: el._id,
        }))
    };

    getSelectedSpecialGroups = (): string => {
        const selectedspecialgroup = this.state.specialgroups.filter(specialgroup => this.state.specialgroupids?.includes(specialgroup._id.toString()));
        return selectedspecialgroup ? selectedspecialgroup.map(specialgroup => specialgroup.name).join("; ") : '';
    };

    getEndDate = (): Date | undefined => {
        if (this.state.repeatType === RepeatTypes.NO_REPEATABLE) {
            return this.state.startDate;
        }
        if (this.state.finishType === FinishTypes.DATE && this.state.endDate) {
            return this.state.endDate;
        } else if (this.state.finishType === FinishTypes.COUNTS && this.state.repeatSeveralTimesCount && this.state.startDate) {
            if (this.state.repeatType === RepeatTypes.DAILY) {
                return getPatientDailyEventEndDate(this.state.startDate, this.state.repeatByDaysCount, this.state.repeatSeveralTimesCount);
            }

            if (this.state.repeatType === RepeatTypes.WEEKLY) {
                return getPatientWeeklyEventEndDate(this.state.startDate, this.state.repeatByWeeksCount, this.state.repeatSeveralTimesCount, this.getSelectedDaysOfWeek());
            }
            if (this.state.repeatType === RepeatTypes.MONTHLY) {
                return getPatientMonthlyEventEndDate(this.state.startDate, this.state.repeatByMonthsCount, this.state.repeatSeveralTimesCount);
            }
        }
        return undefined;
    };

    buildCancellationTimeframeFrom = (date: Date | null): Date | null => {
        if (date) {
            if (!(date instanceof Date)) {
                date = new Date(date!)
            }
            date.setUTCHours(0, 0, 0, 0);
        }

        return date;
    }

    buildCancellationTimeframeTo = (date: Date | null): Date | null => {
        if (date) {
            if (!(date instanceof Date)) {
                date = new Date(date!)
            }
            date.setUTCHours(23, 59, 59, 999);
        }

        return date;
    }

    startCreateEvent = () => {
        this.setState({
            startDate: new Date(),
            openModal: true,
            editMode: false,
            calendarSelectedDate: new Date(),
        });
    }

    onCreateEvent = async (): Promise<void> => {
        let patientIds = this.props.patientIds

        await agent.PatientMassiveEventProvider.create({
            _id: '',
            type: this.props.type ? this.props.type : '',
            patientIds: patientIds ? patientIds : [],
            specialgroupids: this.state.specialgroupids,
            description: this.state.description,
            appointment: this.state.appointment ? this.state.appointment : false,
            appointmentId: this.state.appointmentId ? this.state.appointmentId : '',
            startDate: this.state.startDate ? this.setTimeForDate(this.state.startDate!, this.state.time) : undefined,
            endDate: this.setTimeForDate(this.getEndDate()!, this.state.time),
            cancellationTimeframeFrom: this.buildCancellationTimeframeFrom(this.state.cancellationTimeframeFrom),
            cancellationTimeframeTo: this.buildCancellationTimeframeTo(this.state.cancellationTimeframeTo),
            eventType: this.state.eventType,
            finishType: this.state.finishType,
            repeatType: this.state.repeatType,
            repeatSeveralTimesCount: this.state.repeatSeveralTimesCount,
            repeatByDaysCount: this.state.repeatByDaysCount,
            repeatByWeeksCount: this.state.repeatByWeeksCount,
            repeatByMonthsCount: this.state.repeatByMonthsCount,
            repeatDays: this.getSelectedDaysOfWeek(),
            journal: this.state.journal,
        });
    };

    saveAndKeep = async (): Promise<void> => {
        await this.onCreateEvent();
        await this.setState({
            eventId: '',
            description: ''
        });
        // await this.getEvents();
        alert("Назначение создано. Введите наименование для создания нового назначения с такими же параметрами.")

    }

    saveAndClose = async (): Promise<void> => {
        await this.setState({
            loading: true
        });
        await this.onCreateEvent();
        this.clearState();
        // window.location.reload();
        // await this.getEvents();
    }

    // onEditEvent = async (): Promise<void> => {
    //     console.log('onEditEvent')
    //     if (this.state.startDate && this.getEndDate()) {
    //         await agent.PatientMassiveEventProvider.edit({
    //             _id: this.state.eventId,
    //             type: this.props.type ? this.props.type : '',
    //             patientIds: this.props.patientIds ? this.props.patientIds : [],
    //             description: this.state.description,
    //             startDate: this.setTimeForDate(this.state.startDate!, this.state.time),
    //             endDate: this.setTimeForDate(this.getEndDate()!, this.state.time),
    //             eventType: this.state.eventType,
    //             finishType: this.state.finishType,
    //             repeatType: this.state.repeatType,
    //             repeatSeveralTimesCount: this.state.repeatSeveralTimesCount,
    //             repeatByDaysCount: this.state.repeatByDaysCount,
    //             repeatByWeeksCount: this.state.repeatByWeeksCount,
    //             repeatByMonthsCount: this.state.repeatByMonthsCount,
    //             repeatDays: this.getSelectedDaysOfWeek(),
    //             journal: this.state.journal,
    //             cancellationTimeframeFrom: this.buildCancellationTimeframeFrom(this.state.cancellationTimeframeFrom),
    //             cancellationTimeframeTo: this.buildCancellationTimeframeTo(this.state.cancellationTimeframeTo),
    //         });
    //
    //         this.clearState();
    //
    //         // await this.getEvents();
    //     }
    // };

    onOpenEvent = async (eventId: string): Promise<void> => {
        console.log('onOpenEvent')
        this.setState({/*loading: true,*/ editMode: true, eventId: eventId});

        await agent.PatientMassiveEventProvider.open(eventId).then((patientEvent: PatientMassiveEvent): void => {
            if (patientEvent) {
                this.setState({
                    openModal: true,
                    repeatType: patientEvent.repeatType,
                    eventType: patientEvent.eventType,
                    description: patientEvent.description,
                    appointment: patientEvent.appointment,
                    appointmentId: patientEvent.appointmentId,
                    finishType: patientEvent.finishType,
                    repeatSeveralTimesCount: patientEvent.repeatSeveralTimesCount,
                    repeatByWeeksCount: patientEvent.repeatByWeeksCount,
                    repeatByDaysCount: patientEvent.repeatByDaysCount,
                    repeatDays: patientEvent.repeatDays,
                    checkboxRepeatDays: this.getCheckedCheckboxes(patientEvent.repeatDays),
                    startDate: new Date(patientEvent.startDate.toString()),
                    endDate: new Date(patientEvent.endDate.toString()),
                    cancellationTimeframeFrom: patientEvent.cancellationTimeframeFrom,
                    cancellationTimeframeTo: patientEvent.cancellationTimeframeTo,
                    time: this.getEventTime(new Date(patientEvent.startDate)),
                    journal: patientEvent.journal,
                    loading: false,
                    editMode: true,
                }, () => {
                    this.setState({prevState: this.state})
                });
            }
        });
    };

    getEventTime = (date: Date): string => {
        return moment(date).tz(getTZ()).format("HH:mm");
    };

    setTimeForDate = (date: Date, time: string): Date => {
        const timeArray: string[] = time.split(':');
        return moment.tz([date.getFullYear(), date.getMonth(), date.getDate(),
            safeParseInt(timeArray[0]), safeParseInt(timeArray[1])], getTZ()).toDate();
    };

    onRemoveEvent = async (): Promise<void> => {
        await agent.PatientMassiveEventProvider.remove(this.state.eventId);

        this.clearState();
        // window.location.reload();
        // await this.getEvents();
    };

    getCheckedCheckboxes = (checkboxes: string[]): Map<string, boolean> => {
        const checkboxesMap: Map<string, boolean> = new Map<string, boolean>();

        checkboxes.forEach((el: string) => {
            checkboxesMap.set(el, true);
        })

        return checkboxesMap;
    };

    onChangeFinishType = (e: FormEvent<HTMLInputElement>, data: CheckboxProps): void => {
        this.setState({finishType: data.value as string});

        if (data.value === FinishTypes.DATE) {
            this.setState({
                repeatSeveralTimesCount: 0,
                eventHasBeenChanged: true
            });
        } else if (data.value === FinishTypes.COUNTS) {
            this.setState({
                endDate: undefined,
                eventHasBeenChanged: true
            });
        }
    };

    finishTypeDateCheckbox = (label: string | undefined = undefined) => {
        return (
            <Form.Group className={styles.formField}>
                <Checkbox
                    className={styles.radioCheckbox}
                    radio
                    name={'checkboxRadioGroup'}
                    value={FinishTypes.DATE}
                    checked={this.state.finishType === FinishTypes.DATE}
                    onChange={this.onChangeFinishType}
                />
                <div className={styles.endDate}>
                    <label> {label ?? "Дата"} </label>
                    <div style={{marginLeft: "6px"}}>
                        <DatePicker
                            selected={this.state.finishType === FinishTypes.DATE && this.state.endDate ? new Date(this.state.endDate) : null}
                            onChange={(date: Date) => {
                                this.setState({
                                    endDate: date,
                                    eventHasBeenChanged: true
                                })
                            }}
                            locale={ru}
                            dateFormat="dd.MM.yyyy"
                            disabled={this.state.finishType !== FinishTypes.DATE}
                        /></div>
                </div>
            </Form.Group>
        )
    };

    afterRepeatCountsCheckbox = () => {
        return (
            <>
                <label> После </label>
                <Form.Input width={2}
                            value={(this.state.finishType === FinishTypes.COUNTS && this.state.repeatSeveralTimesCount !== 0) ? this.state.repeatSeveralTimesCount : ''}
                            disabled={this.state.finishType !== FinishTypes.COUNTS}
                            error={this.isRepeatSeveralTimesError()}
                            onChange={(e: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                this.setState({
                                    repeatSeveralTimesCount: safeParseInt(data.value as string),
                                    eventHasBeenChanged: true
                                })
                            }}
                />
                <label> повторений</label>
            </>
        )
    };

    getDateFields = (): JSX.Element => {
        switch (this.state.repeatType) {
            case RepeatTypes.NO_REPEATABLE: {
                return (
                    <Form.Field width={3}>
                        <label>{this.eventDateLabel}</label>
                        <DatePicker
                            selected={this.state.startDate ? new Date(this.state.startDate) : null}
                            onChange={(date: Date) => {
                                this.setState({
                                    startDate: date,
                                    eventHasBeenChanged: true
                                })
                            }}
                            locale={ru}
                            dateFormat="dd.MM.yyyy"
                        />
                    </Form.Field>
                )
            }

            case RepeatTypes.DAILY: {
                return (
                    <>
                        <Form.Field width={3}>
                            <label>{this.eventStartDateLabel}</label>
                            <DatePicker
                                selected={this.state.startDate ? new Date(this.state.startDate) : null}
                                onChange={(date: Date) => {
                                    this.setState({
                                        startDate: date,
                                        eventHasBeenChanged: true
                                    })
                                }}
                                locale={ru}
                                dateFormat="dd.MM.yyyy"
                            />
                        </Form.Field>

                        <Form.Group className={styles.formField}>
                            <label>Повторять каждый </label>
                            <Form.Input width={2}
                                        value={this.state.repeatByDaysCount === 0 ? '' : this.state.repeatByDaysCount}
                                        onChange={(e: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                            this.setState({
                                                repeatByDaysCount: safeParseInt(data.value as string),
                                                eventHasBeenChanged: true
                                            })
                                        }}
                            />
                            <label> день</label>
                        </Form.Group>

                        <Form.Field>
                            <label>Завершить</label>
                        </Form.Field>
                        <Form.Group className={styles.formField}>
                            <Checkbox
                                className={styles.radioCheckbox}
                                radio
                                name={'checkboxRadioGroup'}
                                value={FinishTypes.COUNTS}
                                checked={this.state.finishType === FinishTypes.COUNTS}
                                onChange={this.onChangeFinishType}
                            />

                            {
                                this.afterRepeatCountsCheckbox()
                            }
                        </Form.Group>
                        {
                            this.finishTypeDateCheckbox()
                        }
                    </>
                )
            }

            case RepeatTypes.WEEKLY: {
                return (
                    <>
                        <Form.Field width={3}>
                            <label>{this.eventStartDateLabel}</label>
                            <DatePicker
                                selected={this.state.startDate ? new Date(this.state.startDate) : null}
                                onChange={(date: Date) => {
                                    this.setState({
                                        startDate: date,
                                        eventHasBeenChanged: true
                                    })
                                }}
                                locale={ru}
                                dateFormat="dd.MM.yyyy"
                            />
                        </Form.Field>

                        <Form.Group className={styles.formField}>
                            <label>Повторять каждую </label>
                            <Form.Input width={2}
                                        value={this.state.repeatByWeeksCount === 0 ? '' : this.state.repeatByWeeksCount}
                                        onChange={(e: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => this.setState({repeatByWeeksCount: safeParseInt(data.value as string)})}

                            />
                            <label> неделю</label>
                        </Form.Group>

                        <Form.Group>
                            {
                                daysCheckboxes.map(item => (
                                    <Checkbox
                                        className={styles.checkboxItem}
                                        key={item.key}
                                        label={item.label}
                                        value={item.name}
                                        defaultChecked={this.state.checkboxRepeatDays.get(item.name)}
                                        onChange={this.onChangeRepeatingDays}
                                    />
                                ))
                            }

                        </Form.Group>

                        <Form.Field>
                            <label>Завершить</label>
                        </Form.Field>
                        <Form.Group className={styles.formField}>
                            <Checkbox
                                className={styles.radioCheckbox}
                                radio
                                name={'checkboxRadioGroup'}
                                value={FinishTypes.COUNTS}
                                checked={this.state.finishType === FinishTypes.COUNTS}
                                onChange={this.onChangeFinishType}
                            />

                            {
                                this.afterRepeatCountsCheckbox()
                            }
                        </Form.Group>
                        {
                            this.finishTypeDateCheckbox()
                        }
                    </>

                )
            }

            case RepeatTypes.MONTHLY: {
                return (
                    <>
                        <Form.Field width={3}>
                            <label>{this.eventStartDateLabel}</label>
                            <DatePicker
                                selected={this.state.startDate ? new Date(this.state.startDate) : null}
                                onChange={(date: Date) => {
                                    this.setState({
                                        startDate: date,
                                        eventHasBeenChanged: true
                                    })
                                }}
                                locale={ru}
                                dateFormat="dd.MM.yyyy"
                            />
                        </Form.Field>

                        <Form.Group className={styles.formField}>
                            <label>Повторять каждый </label>
                            <Form.Input width={2}
                                        value={this.state.repeatByMonthsCount === 0 ? '' : this.state.repeatByMonthsCount}
                                        onChange={(e: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
                                            this.setState({
                                                repeatByMonthsCount: safeParseInt(data.value as string),
                                                eventHasBeenChanged: true
                                            })
                                        }}
                            />
                            <label> месяц</label>
                        </Form.Group>

                        <Form.Field>
                            <label>Завершить</label>
                        </Form.Field>
                        <Form.Group className={styles.formField}>
                            <Checkbox
                                className={styles.radioCheckbox}
                                radio
                                name={'checkboxRadioGroup'}
                                value={FinishTypes.COUNTS}
                                checked={this.state.finishType === FinishTypes.COUNTS}
                                onChange={this.onChangeFinishType}
                            />

                            {
                                this.afterRepeatCountsCheckbox()
                            }
                        </Form.Group>
                        {
                            this.finishTypeDateCheckbox("До даты")
                        }
                    </>
                )
            }

            default: {
                return (
                    <></>
                )
            }
        }
    };

    static getEventTypesOptions = (): DropdownOptions[] => {
        return [
            !(Utils.vomode()!=AppMode.PATIENT) ?
                {
                    key: EventTypes.GET_TESTED,
                    text: EventTypes.GET_TESTED,
                    value: EventTypes.GET_TESTED,
                } : {
                    key: EventTypes.SUPPORT_MEASURES,
                    text: EventTypes.SUPPORT_MEASURES,
                    value: EventTypes.SUPPORT_MEASURES,
                },
            ...!(Utils.vomode()!=AppMode.PATIENT) ? [
                {
                    key: EventTypes.TAKE_CURE,
                    text: EventTypes.TAKE_CURE,
                    value: EventTypes.TAKE_CURE,
                },
                {
                    key: EventTypes.VISIT_DOCTOR,
                    text: EventTypes.VISIT_DOCTOR,
                    value: EventTypes.VISIT_DOCTOR,
                }] : [],
            {
                key: EventTypes.INFO,
                text: EventTypes.INFO,
                value: EventTypes.INFO,
            },
            !(Utils.vomode()!=AppMode.PATIENT) ?
                {
                    key: EventTypes.HOSPITALIZATION,
                    text: EventTypes.HOSPITALIZATION,
                    value: EventTypes.HOSPITALIZATION,
                } : {
                    key: EventTypes.DATE_SOCIAL_WORKER,
                    text: EventTypes.DATE_SOCIAL_WORKER,
                    value: EventTypes.DATE_SOCIAL_WORKER,
                },
            !(Utils.vomode()!=AppMode.PATIENT) ?
                {
                    key: EventTypes.REMINDER,
                    text: EventTypes.REMINDER,
                    value: EventTypes.REMINDER,
                } : {
                    key: EventTypes.REMINDER_VO,
                    text: EventTypes.REMINDER_VO,
                    value: EventTypes.REMINDER_VO,
                },
        ];
    };

    getRepeatTypesOptions = (): DropdownOptions[] => {
        let options = [
            {
                key: RepeatTypes.NO_REPEATABLE,
                text: RepeatTypes.NO_REPEATABLE,
                value: RepeatTypes.NO_REPEATABLE,
            },
            {
                key: RepeatTypes.DAILY,
                text: RepeatTypes.DAILY,
                value: RepeatTypes.DAILY,
            },
            {
                key: RepeatTypes.WEEKLY,
                text: RepeatTypes.WEEKLY,
                value: RepeatTypes.WEEKLY,
            }];
        if (window.config?.moniki == true || Utils.vomode()!=AppMode.PATIENT) {
            options.push({
                key: RepeatTypes.MONTHLY,
                text: RepeatTypes.MONTHLY,
                value: RepeatTypes.MONTHLY,
            });
        }
        return options;
    };

    // getEvents = async (): Promise<void> => {
    //     // let calendarEvents: CalendarEvent[] = [];
    //
    //     const eventDescriptionList: PatientMassiveEvent[] = await agent.PatientMassiveEventProvider.getList();
    //
    //     if (eventDescriptionList) {
    //         eventDescriptionList.forEach((item: PatientMassiveEvent) => {
    //             const eventArray = EventRepeater(item);
    //             calendarEvents.push(...eventArray);
    //         });
    //
    //         this.setState({events: calendarEvents, loading: false});
    //     } else {
    //         this.setState({loading: false});
    //     }
    // };

    getSelectedDaysOfWeek = (): string[] => {
        let daysArray: string[] = [];

        this.state.checkboxRepeatDays.forEach((value: boolean, key: string) => {
            if (value) {
                daysArray.push(key);
            }
        })

        return daysArray;
    };

    isRepeatSeveralTimesError = (): boolean => {
        return (this.state.repeatSeveralTimesCount > 500);
    }

    isEndDateError = (): boolean => {
        return !this.getEndDate() || (this.getEndDate()! > (new Date(2100, 1, 1)));
    }

    isStartDateError = (): boolean => {
        return !this.state.startDate || (this.state.startDate > (new Date(2100, 1, 1)));
    }

    isSaveDisabled = (): boolean => {
        console.log(`isSaveDisabled called`);

        const date = new Date();
        const now = moment.tz([date.getFullYear(), date.getMonth(), date.getDate(),
            date.getHours(), date.getMinutes()], getTZ()).toDate();
        const isValidCancellationTimeframeFields = (): boolean => {
            const isEmptyField = (value: any): boolean => {
                return value === null || value === 'Invalid Date';
            }

            return isEmptyField(this.state.cancellationTimeframeFrom) === isEmptyField(this.state.cancellationTimeframeTo);
        }

        if (this.state.eventId &&
            !this.state.eventHasBeenChanged &&
            this.state.cancellationTimeframeHasBeenChanged &&
            isValidCancellationTimeframeFields()
        ) {
            console.log(`isSaveDisabled return false 1`);
            return false;
        }

        if (this.isRepeatSeveralTimesError() || this.isEndDateError()) {
            console.log(`isSaveDisabled return true 1`);
            return true;
        }

        if (this.isStartDateError()) {
            console.log(`isSaveDisabled return true 2`);
            return true;
        }

        if (this.state.prevState) {
            if (
                this.state.eventType === this.state.prevState.eventType &&
                this.state.description === this.state.prevState.description &&
                this.state.repeatType === this.state.prevState.repeatType &&
                this.state.repeatByDaysCount === this.state.prevState.repeatByDaysCount &&
                this.state.time === this.state.prevState.time &&
                this.state.repeatByWeeksCount === this.state.prevState.repeatByWeeksCount &&
                this.state.repeatByMonthsCount === this.state.prevState.repeatByMonthsCount &&
                this.state.repeatSeveralTimesCount === this.state.prevState.repeatSeveralTimesCount &&
                this.state.startDate?.toDateString() === this.state.prevState.startDate?.toDateString() &&
                this.state.endDate?.toDateString() === this.state.prevState.endDate?.toDateString()
            ) {
                console.log(`isSaveDisabled return true 3`);
                return true;
            }
        }

        if (this.state.prevState) {
            if (
                this.state.eventHasBeenChanged &&
                this.state.eventType === this.state.prevState.eventType &&
                this.state.description === this.state.prevState.description &&
                this.state.repeatType === this.state.prevState.repeatType &&
                this.state.repeatByDaysCount === this.state.prevState.repeatByDaysCount &&
                this.state.time === this.state.prevState.time &&
                this.state.repeatByWeeksCount === this.state.prevState.repeatByWeeksCount &&
                this.state.repeatByMonthsCount === this.state.prevState.repeatByMonthsCount &&
                this.state.repeatSeveralTimesCount === this.state.prevState.repeatSeveralTimesCount &&
                this.state.startDate?.toDateString() === this.state.prevState.startDate?.toDateString() &&
                this.state.endDate && this.setTimeForDate(this.state.endDate, this.state.time) > now
            ) {
                console.log(`isSaveDisabled return false 2`);
                return false;
            }
        }

        let isRepeatTypeFilled: boolean = false;

        switch (this.state.repeatType) {
            case RepeatTypes.NO_REPEATABLE: {
                isRepeatTypeFilled = true;

                break
            }
            case RepeatTypes.DAILY: {
                if (this.state.finishType === FinishTypes.COUNTS) {
                    isRepeatTypeFilled = !!this.state.repeatByDaysCount && !!this.state.repeatSeveralTimesCount;
                } else {
                    isRepeatTypeFilled = !!this.state.repeatByDaysCount && !!this.state.endDate && this.state.endDate > now;
                }

                break
            }

            case RepeatTypes.WEEKLY: {
                if (this.state.finishType === FinishTypes.COUNTS) {
                    isRepeatTypeFilled = !!this.state.repeatByWeeksCount && !!this.state.repeatSeveralTimesCount && this.getSelectedDaysOfWeek().length > 0;
                } else {
                    isRepeatTypeFilled = !!this.state.repeatByWeeksCount && !!this.state.endDate && this.state.endDate > now && this.getSelectedDaysOfWeek().length > 0;
                }

                break
            }

            case RepeatTypes.MONTHLY: {
                if (this.state.finishType === FinishTypes.COUNTS) {
                    isRepeatTypeFilled = !!this.state.repeatByMonthsCount && !!this.state.repeatSeveralTimesCount;
                } else {
                    isRepeatTypeFilled = !!this.state.repeatByMonthsCount && !!this.state.endDate && this.state.endDate > now;
                }

                break
            }
        }

        let result = !isValidCancellationTimeframeFields() ||
            (this.props.type=='specialgroups' ? !(this.state.specialgroupids.length > 0) : false) ||
            !this.state.repeatType ||
            !this.state.eventType ||
            !this.state.description ||
            (!!this.state.startDate && this.setTimeForDate(this.state.startDate, this.state.time) < now) ||
            !(this.state.time && !this.state.time.includes('_')) ||
            !isRepeatTypeFilled;
        console.log(`isSaveDisabled = ${result}`);

        return result;
    };

    getDefaultTime = (): string => {
        if (this.state.time) {
            return this.state.time;
        } else {
            this.setState({time: '08:00'});

            return '08:00';
        }
    };

    formatText = (e: any, formatType: TypeDescriptionFormatting) => {

        // Получаем начальную и конечную позиции выделенного текста
        const selectionStart = e.target.selectionStart;
        const selectionEnd = e.target.selectionEnd;
        const selection = e.target.value.substring(
            selectionStart,
            selectionEnd,
        );
        const description = this.state.description;
        // Форматируем выделенный текст
        let formattedText = '';
        switch (formatType) {
            case 'bold':
                formattedText = `<b>${selection}</b>`;
                break;
            case 'italic':
                formattedText = `<i>${selection}</i>`;
                break;
            case 'underline':
                formattedText = `<u>${selection}</u>`;
                break;
        }

        // Заменяем выделенный текст отформатированным текстом
        const newDescription = description.substring(0, selectionStart) + formattedText + description.substring(selectionEnd);

        // Обновляем состояние с отформатированным текстом
        this.setState({
            description: newDescription,
            eventHasBeenChanged: true
        });
    };

    clearFormatText = () => {

        // Заменяем отформатированный текст на обычный
        const newDescription = this.state.description.replace(/<[^>]*>/g, '');

        // Обновляем состояние
        this.setState({
            description: newDescription,
            eventHasBeenChanged: true
        });
    };


    render() {
        const modalCreateHeader: string = 'Создать массовую рассылку';
        const modalEditHeader: string = 'Измененить массовую рассылку';


        return (
            <div className={styles.container}>
                {
                    this.state.openModal &&
                    <Modal
                        onClose={this.clearState}
                        open={this.state.openModal}
                        // closeOnDocumentClick={false}
                        // closeOnPortalMouseLeave={false}
                        // closeOnTriggerMouseLeave={false}
                        // closeOnEscape={false}
                    >
                        <Modal.Header>
                            {this.state.editMode ? modalEditHeader : modalCreateHeader}
                            {
                                this.state.calendarSelectedDate &&
                                <span style={{
                                    float: "right",
                                    display: "block"
                                }}>{this.state.calendarSelectedDate.toLocaleDateString()}</span>
                            }
                        </Modal.Header>

                        <Modal.Content>
                            <p>Выполняя данную операцию,  всем выбранным {getPatientTitle(PatientTitleCase.datm)} будет отправлена массовая рассылка.</p>
                            {this.props.type==='all' && <p>Вами отмечены ВСЕ {getPatientTitle(PatientTitleCase.mult)}.</p>}
                            {this.props.type==='selected' && <p>Вами отмечены {this.props.patientIds?.length} {getPatientTitle(PatientTitleCase.ov)}.</p>}
                            <Form>
                                {this.props.type==='specialgroups' &&
                                    <Form.Field>
                                        <label>{getGeneralTitle(GeneralTitleCase.specialgrouptab)}</label>
                                        <div className={styles.special_group_area}>
                                            <div className={styles.special_group_search}>
                                                <input
                                                    type="text"
                                                    value={this.state.searchSpecialGroup}
                                                    onChange={(e) => this.setState({ searchSpecialGroup: e.target.value })}
                                                    placeholder="Поиск"
                                                    style={{ width: '30%' }}
                                                />
                                                <Button basic color='orange' content='Сбросить'
                                                        onClick={() => this.setState({ searchSpecialGroup: '' })}
                                                        style={{ marginLeft: '10px' }}
                                                />
                                            </div>
                                            {this.getSpecialGroupsOptions()
                                                .filter((option) => option.text.toLowerCase().includes(this.state.searchSpecialGroup.toLowerCase()))
                                                .map((option) => (
                                                <label key={option.value} style={{ display: 'flex', alignItems: 'center', fontWeight: 400 }}>
                                                    <input
                                                        type="checkbox"
                                                        value={option.value}
                                                        checked={this.state.specialgroupids.includes(option.value)}
                                                        style={{ marginRight: '5px' }}
                                                        // disabled={!this.state.editable}
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                            const { value, checked } = e.target;
                                                            this.setState((prevState) => {
                                                                if (checked) {
                                                                    return { specialgroupids: [...prevState.specialgroupids, value] };
                                                                } else {
                                                                    return {
                                                                        specialgroupids: prevState.specialgroupids.filter(
                                                                            (id) => id !== value
                                                                        ),
                                                                    };
                                                                }
                                                            });
                                                        }}
                                                    />
                                                    {option.text}
                                                </label>
                                            ))}
                                        </div>
                                    </Form.Field>
                                }
                                <Form.Field width={4}>
                                    <label>Тип задания</label>
                                    <Dropdown
                                        placeholder={'Выберите тип задания'}
                                        selection
                                        options={MassiveEventModal.getEventTypesOptions()}
                                        defaultValue={this.state.eventType}
                                        onChange={(e: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                            this.setState({
                                                eventType: data.value as string,
                                                eventHasBeenChanged: true
                                            })
                                        }}
                                    />
                                </Form.Field>

                                <Form.Checkbox
                                    label={getGeneralTitle(GeneralTitleCase.appointmentstab)}
                                    checked={this.state.appointment ? this.state.appointment : false}
                                    onChange={(e, data) => {
                                        this.setState({ appointment: data.checked ? true : false });
                                        if (!data.checked) this.setState({ appointmentId: '' });
                                    }}
                                />
                                {this.state.appointment ?
                                    <Form.Field width={8}>
                                        <label>Описание задания</label>
                                        <Dropdown
                                            placeholder={`Выберите ${getGeneralTitle(GeneralTitleCase.appointmentsim)}`}
                                            value={this.state.appointmentId}
                                            selection
                                            search
                                            noResultsMessage={'Результаты не найдены'}
                                            options={this.state.appointmentList.map((el) => ({
                                                key: el._id,
                                                text: el.name,
                                                value: el._id,
                                            } as DropdownItemProps))}
                                            onChange={(e: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                                let support = this.state.appointmentList.filter((el) => el._id === data.value);
                                                console.log(support[0].name)
                                                this.setState({
                                                    appointmentId: support[0]._id,
                                                    description: support[0].name,
                                                    eventHasBeenChanged: true
                                                })
                                            }}
                                        />
                                    </Form.Field>
                                    :
                                    <div>
                                        <Radio
                                            label='Панель форматирования'
                                            toggle={true}
                                            onClick={() => { this.setState({descriptionFormatting: !this.state.descriptionFormatting}) }}
                                            active={this.state.descriptionFormatting==true}
                                            className={''}
                                        >
                                        </Radio><br/>

                                        {this.state.descriptionFormatting && (
                                            <>
                                                <ButtonGroup style={{marginTop: '10px'}} size='tiny'>
                                                    <Button
                                                        // basic
                                                        // color='blue'
                                                        onClick={() => { this.setState({descriptionTypeFormatting: 'bold'}) }}
                                                        active={this.state.descriptionTypeFormatting === 'bold'}
                                                    ><Icon name='bold' /></Button>
                                                    <Button
                                                        // basic
                                                        // color='blue'
                                                        onClick={() => { this.setState({descriptionTypeFormatting: 'italic'}) }}
                                                        active={this.state.descriptionTypeFormatting === 'italic'}
                                                    ><Icon name='italic' /></Button>
                                                    <Button
                                                        // basic
                                                        // color='blue'
                                                        onClick={() => { this.setState({descriptionTypeFormatting: 'underline'}) }}
                                                        active={this.state.descriptionTypeFormatting === 'underline'}
                                                    ><Icon name='underline' /></Button>
                                                </ButtonGroup>
                                                <Button
                                                    style={{marginLeft: '30px'}}
                                                    size='tiny'
                                                    basic={true}
                                                    onClick={() => { this.clearFormatText() }}
                                                ><Icon name='ban' /></Button>
                                            </>)}

                                        <Form.TextArea
                                            width={8}
                                            maxLength={300}
                                            label={'Описание задания'}
                                            value={this.state.description}
                                            onChange={(e: ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
                                                this.setState({
                                                    description: data.value as string,
                                                    eventHasBeenChanged: true
                                                })
                                            }}
                                            onSelect={
                                                (e: any) => {
                                                    if (this.state.descriptionFormatting) {
                                                        const log = document.getElementById("log");
                                                        const selection = e.target.value.substring(
                                                            e.target.selectionStart,
                                                            e.target.selectionEnd,
                                                        );
                                                        if (selection.length > 0 && log) {
                                                            this.formatText(e, this.state.descriptionTypeFormatting);
                                                        }
                                                    }
                                                }
                                            }
                                        />
                                        <p id="log"></p>
                                        {this.state.description.includes('<b>') &&
                                            <Form.Field width={8}>
                                                <label>Предпросмотр задания</label>
                                                <Segment style={{margin: '0 0 1rem 0'}} secondary>
                                                    <p
                                                        dangerouslySetInnerHTML={{ __html: this.state.description.replace(/<b>/g, '<strong>').replace(/<\/b>/g, '</strong>').replace(/<i>/g, '<em>').replace(/<\/i>/g, '</em>').replace(/<u>/g, '<u>').replace(/<\/u>/g, '</u>') }}>
                                                    </p>
                                                </Segment>
                                            </Form.Field>
                                        }
                                    </div>
                                }

                                {/*}*/}

                                <Form.Field width={4}>
                                    <label>Повторение</label>
                                    <Dropdown
                                        placeholder={'Выберите тип повторения'}
                                        selection
                                        options={this.getRepeatTypesOptions()}
                                        defaultValue={this.state.repeatType}
                                        onChange={(e: SyntheticEvent<HTMLElement>, data: DropdownProps) => {
                                            this.setState({
                                                repeatType: data.value as string,
                                                eventHasBeenChanged: true
                                            })
                                        }}
                                    />
                                </Form.Field>

                                <Form.Field>
                                    <label>Исключение</label>
                                    <div style={{ display: "flex" }}><div style={{ width: "161px" }}>
                                        <DatePicker
                                            selected={this.state.cancellationTimeframeFrom ? new Date(this.state.cancellationTimeframeFrom) : null}
                                            onChange={(date: Date) => {
                                                this.setState({
                                                    cancellationTimeframeFrom: date,
                                                    cancellationTimeframeHasBeenChanged: true
                                                })
                                            }}
                                            locale={ru}
                                            dateFormat="dd.MM.yyyy"
                                        /></div>
                                        <span style={{lineHeight: "38px"}}> - </span>
                                        <div style={{ width: "161px" }}>
                                            <DatePicker
                                                selected={this.state.cancellationTimeframeTo ? new Date(this.state.cancellationTimeframeTo) : null}
                                                onChange={(date: Date) => {
                                                    this.setState({
                                                        cancellationTimeframeTo: date,
                                                        cancellationTimeframeHasBeenChanged: true
                                                    })
                                                }}
                                                locale={ru}
                                                dateFormat="dd.MM.yyyy"
                                            /></div></div>
                                </Form.Field>

                                {
                                    this.state.repeatType && this.getDateFields()
                                }

                                <Form.Field width={3}>
                                    <label>Время напоминания</label>
                                </Form.Field>
                                <Form.Field width={2}>
                                    <ReactInputMask
                                        mask={'99:99'}
                                        alwaysShowMask={false}
                                        value={this.state.time}
                                        style={{ margin: "0 0 10em 0" }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            let time: string = e.target.value;
                                            const hours = time.substring(0, 2);
                                            const minutes = time.substring(3, 5);
                                            if (parseInt(hours, 10) >= 24) {
                                                time = `23:${minutes}`;
                                            } else if (parseInt(minutes, 10) >= 60) {
                                                time = `${hours}:59`;
                                            }
                                            this.setState({
                                                time: time,
                                                eventHasBeenChanged: true
                                            });
                                        }}
                                    />
                                </Form.Field>

                            </Form>

                        </Modal.Content>

                        <Modal.Actions>

                            {
                                this.state.editMode ?
                                    <>
                                        <Button
                                            color={'yellow'}
                                            content={'Удалить'}
                                            onClick={this.onRemoveEvent}
                                        />
                                        {/*<Button*/}
                                        {/*    content={'Изменить'}*/}
                                        {/*    disabled={this.isSaveDisabled()}*/}
                                        {/*    onClick={this.onEditEvent}*/}
                                        {/*    positive*/}
                                        {/*/>*/}
                                    </>
                                    :
                                    <>
                                        {/*<Button*/}
                                        {/*    content={'Сохранить и продолжить'}*/}
                                        {/*    disabled={this.isSaveDisabled()}*/}
                                        {/*    onClick={this.saveAndKeep}*/}
                                        {/*    color={'blue'}*/}
                                        {/*/>*/}
                                        {this.state.loading &&
                                            <Message icon>
                                                <Icon name='circle notched' loading />
                                                <MessageContent>
                                                    <MessageHeader>Ожидайте и не закрывайте окно</MessageHeader>
                                                    Создаётся массовая рассылка.
                                                </MessageContent>
                                            </Message>
                                        }
                                        <Button
                                            content={'Сохранить'}
                                            disabled={this.isSaveDisabled() || this.state.loading}
                                            onClick={this.saveAndClose}
                                            positive
                                        />
                                    </>
                            }

                            <Button
                                content={'Отмена'}
                                color={'grey'}
                                onClick={this.clearState}
                            />
                        </Modal.Actions>
                    </Modal>
                }

                {/*{*/}
                {/*    this.state.loading ?*/}
                {/*        <Loader active/>*/}
                {/*        :*/}
                {/*        <div>*/}
                {/*            <Form>*/}
                {/*                <Form.Group className={styles.formField}>*/}
                {/*                    <Form.Button onClick={this.startCreateEvent}*/}
                {/*                                 color={'green'}*/}
                {/*                                 type={'submit'}>*/}
                {/*                        <label>*/}
                {/*                            Добавить*/}
                {/*                        </label>*/}
                {/*                    </Form.Button>*/}
                {/*                    <Form.Field width={"3"}>*/}
                {/*                        <label>*/}
                {/*                            Тип заданий*/}
                {/*                        </label>*/}
                {/*                        <Dropdown*/}
                {/*                            selection*/}
                {/*                            clearable={true}*/}
                {/*                            value={this.state.filterEventType}*/}
                {/*                            onChange={(event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {*/}
                {/*                                this.setState({filterEventType: (data.value && data.value !== "" ? data.value.toString() : undefined)},*/}
                {/*                                    this.getEvents)*/}
                {/*                            }}*/}
                {/*                            options={MassiveEventModal.getEventTypesOptions()}*/}
                {/*                        />*/}
                {/*                    </Form.Field>*/}
                {/*                </Form.Group>*/}
                {/*            </Form>*/}

                {/*            <MediaQuery minWidth={1224}>*/}
                {/*                <FullCalendar*/}
                {/*                    plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin, listPlugin]}*/}
                {/*                    headerToolbar={{*/}
                {/*                        left: 'prev,next today',*/}
                {/*                        center: 'title',*/}
                {/*                        right: 'dayGridMonth,timeGridWeek,timeGridDay,month,year'*/}

                {/*                    }}*/}
                {/*                    views={{*/}
                {/*                        year: {*/}
                {/*                            type: 'listYear',*/}
                {/*                            buttonText: 'Повестка год'*/}
                {/*                        },*/}
                {/*                        month: {*/}
                {/*                            type: 'listMonth',*/}
                {/*                            buttonText: 'Повестка месяц'*/}
                {/*                        }*/}
                {/*                    }}*/}
                {/*                    nextDayThreshold={'02:00:00'}*/}
                {/*                    locale={ruLocale}*/}
                {/*                    displayEventTime={true}*/}
                {/*                    initialView="dayGridMonth"*/}
                {/*                    events={this.state.events}*/}
                {/*                    eventClick={(e: EventClickArg) => {*/}
                {/*                        this.setState({*/}
                {/*                            calendarSelectedDate: e.event.start,*/}
                {/*                        });*/}
                {/*                        this.onOpenEvent(e.event.groupId.toString());*/}
                {/*                    }}*/}
                {/*                    dateClick={(arg: DateClickArg) => {*/}
                {/*                        this.setState({*/}
                {/*                            startDate: new Date(arg.dateStr),*/}
                {/*                            time: arg.date.getHours() ? moment(arg.date).format("HH:mm") : "",*/}
                {/*                            openModal: true,*/}
                {/*                            editMode: false,*/}
                {/*                            calendarSelectedDate: new Date(arg.dateStr),*/}
                {/*                        });*/}
                {/*                    }}*/}
                {/*                    eventTimeFormat={{*/}
                {/*                        hour: '2-digit',*/}
                {/*                        minute: '2-digit',*/}
                {/*                        meridiem: false*/}
                {/*                    }}*/}

                {/*                    eventDidMount={(arg) => {*/}
                {/*                        const d = arg.event.start!;*/}
                {/*                        const dd = [d.getHours(), d.getMinutes()].map((a) => (a < 10 ? '0' + a : a));*/}
                {/*                        let s = dd.join(':') + ": " + arg.event.title;*/}
                {/*                        arg.el.setAttribute("title", s);*/}
                {/*                    }}*/}

                {/*                    initialDate={this.state.currentCalendarDate}*/}
                {/*                    datesSet={(arg) => {*/}
                {/*                        this.setState({currentCalendarDate: arg.view.currentStart})*/}
                {/*                    }}*/}
                {/*                />*/}
                {/*            </MediaQuery>*/}
                {/*            <MediaQuery maxWidth={1224}>*/}
                {/*                <FullCalendar*/}
                {/*                    plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin, listPlugin]}*/}
                {/*                    headerToolbar={{*/}
                {/*                        left: 'prev,next today',*/}
                {/*                        center: 'title',*/}
                {/*                        right: 'month,year'*/}

                {/*                    }}*/}
                {/*                    views={{*/}
                {/*                        year: {*/}
                {/*                            type: 'listYear',*/}
                {/*                            buttonText: 'Повестка год'*/}
                {/*                        },*/}
                {/*                        month: {*/}
                {/*                            type: 'listMonth',*/}
                {/*                            buttonText: 'Повестка месяц'*/}
                {/*                        }*/}
                {/*                    }}*/}
                {/*                    locale={ruLocale}*/}
                {/*                    displayEventTime={true}*/}
                {/*                    nextDayThreshold={'02:00:00'}*/}
                {/*                    initialView="listMonth"*/}
                {/*                    events={this.state.events}*/}
                {/*                    eventClick={(e: EventClickArg) => {*/}
                {/*                        this.setState({*/}
                {/*                            calendarSelectedDate: e.event.start,*/}
                {/*                        });*/}
                {/*                        this.onOpenEvent(e.event.groupId.toString());*/}
                {/*                    }}*/}
                {/*                    dateClick={(arg: DateClickArg) => {*/}
                {/*                        this.setState({*/}
                {/*                            startDate: new Date(arg.dateStr),*/}
                {/*                            openModal: true,*/}
                {/*                            editMode: false,*/}
                {/*                            time: arg.date.getHours() ? moment(arg.date).format("HH:mm") : "",*/}
                {/*                            calendarSelectedDate: new Date(arg.dateStr),*/}
                {/*                        });*/}
                {/*                    }}*/}
                {/*                    eventTimeFormat={{*/}
                {/*                        hour: '2-digit',*/}
                {/*                        minute: '2-digit',*/}
                {/*                        meridiem: false*/}
                {/*                    }}*/}

                {/*                    eventDidMount={(arg) => {*/}
                {/*                        const d = arg.event.start!;*/}
                {/*                        const dd = [d.getHours(), d.getMinutes()].map((a) => (a < 10 ? '0' + a : a));*/}
                {/*                        let s = dd.join(':') + ": " + arg.event.title;*/}
                {/*                        arg.el.setAttribute("title", s);*/}
                {/*                    }}*/}

                {/*                    initialDate={this.state.currentCalendarDate}*/}
                {/*                    datesSet={(arg) => {*/}
                {/*                        this.setState({currentCalendarDate: arg.view.currentStart})*/}
                {/*                    }}*/}
                {/*                />*/}
                {/*            </MediaQuery>*/}

                {/*        </div>*/}
                {/*}*/}
            </div>

        );
    }
}

export default MassiveEventModal;
